<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-BaiVietDetail" data-bs-toggle="tab" href="#BaiVietDetail" role="tab"
                aria-controls="BaiVietDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Bài viết [{{BaiVietService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="BaiVietDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-5 col-sm-12 col-12">
                    <div class="row gx-4">
                        <div class="col-lg-7 col-sm-12 col-12">
                            <a style="width: 100%;" title="Lưu thay đổi" (click)="BaiVietSave()" class="btn btn-info"><i
                                    class="bi bi-sd-card"></i> Lưu thay đổi</a>
                        </div>
                        <div class="col-lg-5 col-sm-12 col-12">
                            <div class="form-check form-check-inline mb-6">
                                <input type="checkbox" class="form-check-input" name="Active"
                                    [(ngModel)]="BaiVietService.FormData.Active" />
                                <label class="form-check-label" for="Active">Kích hoạt</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Menu</label>
                        <select class="form-select" name="ParentID" [(ngModel)]="BaiVietService.FormData.ParentID">
                            <option *ngFor="let item of DanhMucBaiVietService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="BaiVietService.FormData.Name" placeholder="Tiêu đề" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mô tả</label>
                        <input name="Description" [(ngModel)]="BaiVietService.FormData.Description" placeholder="Mô tả"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đường dẫn (tự động)</label>
                        <input name="Code" [(ngModel)]="BaiVietService.FormData.Code" placeholder="Đường dẫn (tự động)"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Hình đại diện: </label>
                        <br />
                        <input type="file" (change)="ChangeFileName($event.target.files)">
                        <br />
                        <br />
                        <img src="{{BaiVietService.FormData.FileName}}" class="img-fluid rounded" width="300"
                            height="200">
                    </div>
                </div>
                <div class="col-lg-5 col-sm-12 col-12">
                    <ck-editor [config]="{'height': 500, 'width': 800}" name="HTMLContent"
                        [(ngModel)]="BaiVietService.FormData.HTMLContent" skin="moono-lisa" language="en"
                        [fullPage]="true"></ck-editor>
                </div>
            </div>
            <div class="row gx-4" *ngIf="BaiVietService.FormData.ID>0">
                <div class="card mb-4 card-info">
                    <div class="card-header">
                        <h5 class="card-title text-white">Hình ảnh đính kèm
                            <span *ngIf="BaiVietTapTinDinhKemService.List">({{BaiVietTapTinDinhKemService.List.length}}
                                items)</span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="table-outer">
                            <div class="table-responsive">
                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                    #BaiVietTapTinDinhKemSort="matSort"
                                    [dataSource]="BaiVietTapTinDinhKemService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="STT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            STT
                                        </th>
                                        <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                            <div *ngIf="BaiVietTapTinDinhKemPaginator">
                                                {{BaiVietTapTinDinhKemPaginator.pageSize *
                                                BaiVietTapTinDinhKemPaginator.pageIndex + i + 1}}
                                            </div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="FileName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hình đại diện
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <a *ngIf="element.ID>0" title="{{element.FileName}}" href="{{element.FileName}}"
                                                target="_blank"><img class="img-fluid rounded" width="300" height="200"
                                                    src="{{element.FileName}}" title="{{element.FileName}}"
                                                    alt="{{element.FileName}}" /></a>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input class="form-control" type="text" placeholder="Tiêu đề"
                                                name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Description">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mô tả</th>
                                        <td mat-cell *matCellDef="let element">
                                            <input class="form-control" type="text" placeholder="Mô tả"
                                                name="Description{{element.ID}}" [(ngModel)]="element.Description">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Note">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chọn hình
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <input class="form-control" type="file" multiple
                                                accept="image/png, image/gif, image/jpeg"
                                                (change)="BaiVietTapTinDinhKemChangeFile($event.target.files)">
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Save">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <a class="btn btn-info" (click)="BaiVietTapTinDinhKemSave(element)"><i
                                                    class="bi bi-sd-card"></i>
                                            </a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-danger" (click)="BaiVietTapTinDinhKemDelete(element)"
                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                            </a>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="BaiVietTapTinDinhKemService.DisplayColumns001">
                                    </tr>
                                    <tr mat-row
                                        *matRowDef="let row; columns: BaiVietTapTinDinhKemService.DisplayColumns001;">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                    #BaiVietTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                    [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                    #BaiVietTapTinDinhKemSort="matSort"
                                    [dataSource]="BaiVietTapTinDinhKemService.DataSource"
                                    class="table table-striped m-0">
                                    <ng-container matColumnDef="STT">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Danh sách
                                        </th>
                                        <td mat-cell *matCellDef="let element; index as i;">
                                            <div class="row gx-4">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Tiêu đề</label>
                                                    <input class="form-control" type="text" placeholder="Tiêu đề"
                                                        name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Mô tả</label>
                                                    <input class="form-control" type="text" placeholder="Mô tả"
                                                        name="Description{{element.ID}}"
                                                        [(ngModel)]="element.Description">
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Hình ảnh</label>
                                                    <br />
                                                    <a title="{{element.FileName}}" href="{{element.FileName}}"
                                                        target="_blank"><img class="img-fluid rounded" width="300"
                                                            height="200" src="{{element.FileName}}"
                                                            title="{{element.FileName}}"
                                                            alt="{{element.FileName}}" /></a>
                                                </div>
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <label class="form-label">Chọn hình</label>
                                                    <br />
                                                    <input class="form-control" type="file" multiple
                                                        accept="image/png, image/gif, image/jpeg"
                                                        (change)="BaiVietTapTinDinhKemChangeFile($event.target.files)">
                                                </div>
                                                <div class="row gx-4">
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-info" style="width: 100%;"
                                                            (click)="BaiVietTapTinDinhKemSave(element)"><i
                                                                class="bi bi-sd-card"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                        <a class="btn btn-danger" style="width: 100%;"
                                                            (click)="BaiVietTapTinDinhKemDelete(element)"
                                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                        </a>
                                                    </div>
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row
                                        *matHeaderRowDef="BaiVietTapTinDinhKemService.DisplayColumnsMobile">
                                    </tr>
                                    <tr mat-row
                                        *matRowDef="let row; columns: BaiVietTapTinDinhKemService.DisplayColumnsMobile;">
                                    </tr>
                                </table>
                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                    #BaiVietTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                    [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="BaiVietService.IsShowLoading"></app-loading>