import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DanhMucBaiVietComponent } from './danh-muc-bai-viet/danh-muc-bai-viet.component';
import { BaiVietComponent } from './bai-viet/bai-viet.component';


const routes: Routes = [
  { path: '', redirectTo: '/BaiViet', pathMatch: 'full' },
  {
    path: 'DanhMucBaiViet', component: DanhMucBaiVietComponent,
  },
  {
    path: 'BaiViet', component: BaiVietComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }









































































