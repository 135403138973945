import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaiVietTapTinDinhKem } from 'src/app/shared/BaiVietTapTinDinhKem.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class BaiVietTapTinDinhKemService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'FileName', 'Name', 'Description', 'Note', 'Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "BaiVietTapTinDinhKem";
    }
}

