import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DanhMucBaiViet } from 'src/app/shared/DanhMucBaiViet.model';
import { DanhMucBaiVietService } from 'src/app/shared/DanhMucBaiViet.service';


import { BaiViet } from 'src/app/shared/BaiViet.model';
import { BaiVietService } from 'src/app/shared/BaiViet.service';

import { BaiVietTapTinDinhKem } from 'src/app/shared/BaiVietTapTinDinhKem.model';
import { BaiVietTapTinDinhKemService } from 'src/app/shared/BaiVietTapTinDinhKem.service';

@Component({
  selector: 'app-bai-viet-detail',
  templateUrl: './bai-viet-detail.component.html',
  styleUrls: ['./bai-viet-detail.component.css']
})
export class BaiVietDetailComponent implements OnInit {

  @ViewChild('BaiVietTapTinDinhKemSort') BaiVietTapTinDinhKemSort: MatSort;
  @ViewChild('BaiVietTapTinDinhKemPaginator') BaiVietTapTinDinhKemPaginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<BaiVietDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public DanhMucBaiVietService: DanhMucBaiVietService,

    public BaiVietService: BaiVietService,
    public BaiVietTapTinDinhKemService: BaiVietTapTinDinhKemService,

  ) { }

  ngOnInit(): void {
    this.BaiVietSearch();
  }
  DanhMucBaiVietSearch() {
    this.DanhMucBaiVietService.ComponentGetAllToListAsync(this.BaiVietService);
  }

  BaiVietTapTinDinhKemSearch() {
    this.BaiVietTapTinDinhKemService.BaseParameter.ParentID = this.BaiVietService.FormData.ID;
    this.BaiVietTapTinDinhKemService.SearchByParentID(this.BaiVietTapTinDinhKemSort, this.BaiVietTapTinDinhKemPaginator, this.BaiVietService);
  }
  BaiVietTapTinDinhKemSave(element: BaiVietTapTinDinhKem) {
    this.BaiVietService.IsShowLoading = true;
    element.ParentID = this.BaiVietService.FormData.ID;
    this.BaiVietTapTinDinhKemService.FormData = element;
    this.BaiVietTapTinDinhKemService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.BaiVietTapTinDinhKemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      },
      () => {
        this.BaiVietService.IsShowLoading = false;
      }
    );
  }
  BaiVietTapTinDinhKemDelete(element: BaiVietTapTinDinhKem) {
    this.BaiVietTapTinDinhKemService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.BaiVietTapTinDinhKemService.ComponentDeleteByParentID(this.BaiVietTapTinDinhKemSort, this.BaiVietTapTinDinhKemPaginator, this.BaiVietService));
  }
  BaiVietTapTinDinhKemChangeFile(files: FileList) {
    if (files) {
      this.BaiVietTapTinDinhKemService.FileToUpload = files;
    }
  }

  fileToUpload0: File = null;
  ChangeFileName(files: FileList) {
    if (files) {
      this.BaiVietService.FileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.BaiVietService.FormData.FileName = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
  BaiVietSearch() {
    this.BaiVietService.IsShowLoading = true;
    this.BaiVietService.GetByIDAsync().subscribe(
      res => {
        this.BaiVietService.FormData = (res as BaiViet);
        this.DanhMucBaiVietSearch();
        this.BaiVietTapTinDinhKemSearch();
      },
      err => {
      },
      () => {
        this.BaiVietService.IsShowLoading = false;
      }
    );
  }
  BaiVietSave() {
    this.BaiVietService.IsShowLoading = true;
    this.BaiVietService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.BaiVietService.FormData = (res as BaiViet);
        this.BaiVietTapTinDinhKemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      },
      () => {
        this.BaiVietService.IsShowLoading = false;
      }
    );
  }
  Close() {
    this.dialogRef.close();
  }
}
