import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';


import { DanhMucBaiViet } from 'src/app/shared/DanhMucBaiViet.model';
import { DanhMucBaiVietService } from 'src/app/shared/DanhMucBaiViet.service';

@Component({
  selector: 'app-danh-muc-bai-viet',
  templateUrl: './danh-muc-bai-viet.component.html',
  styleUrls: ['./danh-muc-bai-viet.component.css']
})
export class DanhMucBaiVietComponent implements OnInit {

  @ViewChild('DanhMucBaiVietSort') DanhMucBaiVietSort: MatSort;
  @ViewChild('DanhMucBaiVietPaginator') DanhMucBaiVietPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucBaiVietService: DanhMucBaiVietService,
  ) { }

  ngOnInit(): void {    
  }

  DanhMucBaiVietSearch() {
    this.DanhMucBaiVietService.SearchAll(this.DanhMucBaiVietSort, this.DanhMucBaiVietPaginator);
  }
  DanhMucBaiVietSave(element: DanhMucBaiViet) {
    this.DanhMucBaiVietService.FormData = element;
    this.NotificationService.warn(this.DanhMucBaiVietService.ComponentSaveAll(this.DanhMucBaiVietSort, this.DanhMucBaiVietPaginator));
  }
  DanhMucBaiVietDelete(element: DanhMucBaiViet) {
    this.DanhMucBaiVietService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucBaiVietService.ComponentDeleteAll(this.DanhMucBaiVietSort, this.DanhMucBaiVietPaginator));
  }  
}