<nav id="sidebar" class="sidebar-wrapper">
      <div class="app-brand p-3 mb-3">
            <div class="row">
                  <div class="col-9">
                        <a href="#">
                              <span id="account-name">&nbsp;&nbsp;&nbsp;{{PageTitleShort}}</span></a>
                  </div>
                  <div class="col-3">
                        <div class="d-flex float-end">
                              <button class="toggle-sidebar" id="toggle-sidebar-close-mobile">
                                    <i class="bi bi-x-square lh-1"></i>
                              </button>
                        </div>
                  </div>
            </div>
      </div>
      <div class="sidebarMenuScroll">
            <ul class="sidebar-menu">
                  <li>
                        <a routerLink="/BaiViet">
                              <i class="bi bi-bank2"></i>
                              <span class="menu-text">Bài viết</span>
                        </a>
                  </li>
                  <li>
                        <a routerLink="/DanhMucBaiViet">
                              <i class="bi bi-bank2"></i>
                              <span class="menu-text">Menu</span>
                        </a>
                  </li>
            </ul>
      </div>
</nav>
<div class="app-container">
      <div class="app-header d-flex align-items-center">
            <div class="d-flex">
                  <button class="pin-sidebar">
                        <i class="bi bi-list lh-1"></i>
                  </button>
            </div>
            <div class="app-brand-sm d-lg-none d-flex">
            </div>
            <div class="search-container d-lg-block d-none mx-3">
                  <input type="text" class="form-control" placeholder="{{PageDescription}}" />
            </div>
            <div class="header-actions">
                  <div class="d-sm-flex d-none gap-3">
                  </div>
                  <div class="dropdown ms-4">
                  </div>
                  <div class="d-flex">
                        <button class="toggle-sidebar">
                              <i class="bi bi-list lh-1"></i>
                        </button>
                  </div>
            </div>
      </div>
      <hr />
      <router-outlet></router-outlet>
</div>