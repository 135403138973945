import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { DanhMucBaiViet } from 'src/app/shared/DanhMucBaiViet.model';
import { DanhMucBaiVietService } from 'src/app/shared/DanhMucBaiViet.service';


import { BaiViet } from 'src/app/shared/BaiViet.model';
import { BaiVietService } from 'src/app/shared/BaiViet.service';
import { BaiVietDetailComponent } from '../bai-viet-detail/bai-viet-detail.component';

@Component({
  selector: 'app-bai-viet',
  templateUrl: './bai-viet.component.html',
  styleUrls: ['./bai-viet.component.css']
})
export class BaiVietComponent implements OnInit {

  @ViewChild('BaiVietSort') BaiVietSort: MatSort;
  @ViewChild('BaiVietPaginator') BaiVietPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    public DanhMucBaiVietService: DanhMucBaiVietService,
    

    public BaiVietService: BaiVietService,
  ) { }

  ngOnInit(): void {
    this.DanhMucBaiVietSearch();
  }
  DanhMucBaiVietSearch() {
    this.DanhMucBaiVietService.ComponentGetAllToListAsync(this.BaiVietService);
  }  
  BaiVietSearch() {
    this.BaiVietService.IsShowLoading = true;    
    this.BaiVietService.GetByParentID_SearchStringToListAsync().subscribe(
      res => {
        this.BaiVietService.List = (res as BaiViet[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.BaiVietService.DataSource = new MatTableDataSource(this.BaiVietService.List);
        this.BaiVietService.DataSource.sort = this.BaiVietSort;
        this.BaiVietService.DataSource.paginator = this.BaiVietPaginator;
      },
      err => {
      },
      () => {
        this.BaiVietService.IsShowLoading = false;
      }
    );
  }
  BaiVietDelete(element: BaiViet) {
    this.BaiVietService.IsShowLoading = true;    
    this.BaiVietService.BaseParameter.ID = element.ID;    
    this.BaiVietService.RemoveAsync().subscribe(
      res => {
        this.BaiVietSearch();
        this.NotificationService.warn(environment.DeleteSuccess);
      },
      err => {
        this.NotificationService.warn(environment.DeleteNotSuccess);
      },
      () => {
        this.BaiVietService.IsShowLoading = false;
      }
    );
  }
  BaiVietAdd(ID: number) {
    this.BaiVietService.BaseParameter.ID = ID;
    this.BaiVietService.GetByIDAsync().subscribe(
      res => {
        this.BaiVietService.FormData = res as BaiViet;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(BaiVietDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.BaiVietSearch();
        });
      },
      err => {
      }
    );
  }
}